import React, { useCallback, useEffect, useRef } from 'react';
import { useSlotPlaceholder } from '@wix/widget-plugins-ooi';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { resolveId } from '@wix/communities-blog-client-common';
import {
  ABOVE_CONTENT_1,
  ABOVE_CONTENT_2,
  PAGE_BOTTOM_1,
  PAGE_BOTTOM_2,
  PAGE_BOTTOM_3,
} from '@app/constants/ooi-slots';
import AnimatedLoader from '@app/external/common/components/animated-loader';
import PrintWrapper from '@app/external/common/components/print-wrapper';
import { useActions, useSelector } from '@app/external/common/components/runtime-context';
import { getRoute } from '@app/external/common/router/router-selectors';
import { getPostByIdOrSlug } from '@app/external/common/selectors/post-selectors';
import { isInPostPreview } from '@app/external/common/services/detect-route';
import scrollToContent from '@app/external/common/services/scroll-to-content';
import { resolvePostSlug } from '@app/external/common/services/slug';
import { getIsPostLoaded } from '@app/external/common/store/is-loaded/is-loaded-selectors';
import { type NormalizedPost } from '@app/external/common/types';
import ReadingTimeListener, {
  type OnScrollHandler,
  type OnTabVisibilityChangeHandler,
} from '../../components/reading-time-listener/use-reading-time-listener';
import { type RoutePostParams } from '../../constants/routes';
import { getReadingSessionId } from '../../selectors/reading-session-id-selector';
import type IPostPageCommentsSection from './post-page-comments-section';
import PostPagePost from './post-page-post';
import PostPageRelatedPostsSection from './post-page-related-posts-section';
import styles from './post-page-root.scss';

const PostPageCommentsSection = loadable(
  () => import(/* webpackChunkName: "post-page-wix-comments" */ './post-page-comments-section'),
) as typeof IPostPageCommentsSection;

type Props = {
  params: RoutePostParams;
};

const PostPageRoot = (props: Props) => {
  const { post, postSlug, readingSessionId, isPostLoaded } = usePostPageSlice(props);
  const { isSSR, isEditor } = useEnvironment();
  const actions = useActions();
  const [forceComments, setForceComments] = React.useState(false);
  const commentsRef = React.useRef<HTMLDivElement>(null);
  const [AboveContent1SlotsPlaceholder, isAboveContent1SlotEmpty] =
    useSlotPlaceholder(ABOVE_CONTENT_1);
  const [AboveContent2SlotsPlaceholder, isAboveContent2SlotEmpty] =
    useSlotPlaceholder(ABOVE_CONTENT_2);
  const [PageBottom1SlotsPlaceholder, isPageBottom1SlotEmpty] = useSlotPlaceholder(PAGE_BOTTOM_1);
  const [PageBottom2SlotsPlaceholder, isPageBottom2SlotEmpty] = useSlotPlaceholder(PAGE_BOTTOM_2);
  const [PageBottom3SlotsPlaceholder, isPageBottom3SlotEmpty] = useSlotPlaceholder(PAGE_BOTTOM_3);

  const biElement = useBiElement({
    post,
    biActiveTabChanged: actions.biActiveTabChanged,
    biPostScrolled: actions.biPostScrolled,
    readingSessionId,
    slug: postSlug,
    trackEvent: actions.trackEvent,
    onSlugChange: () => {
      scrollToContent(styles.root);
    },
  });

  const handleRatingsDisplayClick = () => {
    if (!forceComments) {
      setForceComments(true);
    }

    requestAnimationFrame(() => {
      commentsRef.current?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    });
  };

  return (
    <div className={styles.root} data-hook="post-page">
      {!isSSR && !isEditor && biElement}
      <div className={!isAboveContent1SlotEmpty ? styles.aboveContentSlot : ''}>
        <AboveContent1SlotsPlaceholder />
      </div>
      <div className={!isAboveContent2SlotEmpty ? styles.aboveContentSlot : ''}>
        <AboveContent2SlotsPlaceholder />
      </div>
      <div className={styles.wrapper}>
        <AnimatedLoader isLoading={!isPostLoaded && !post}>
          <PrintWrapper>
            <PostPagePost
              key={resolveId(post)}
              post={post}
              onRatingsDisplayClick={handleRatingsDisplayClick}
            />
          </PrintWrapper>
        </AnimatedLoader>
        {!isPageBottom1SlotEmpty && (
          <div className={styles.pageBottomSlot}>
            <PageBottom1SlotsPlaceholder />
          </div>
        )}
        <PostPageRelatedPostsSection post={post} />
        {!isPageBottom2SlotEmpty && (
          <div className={styles.pageBottomSlot}>
            <PageBottom2SlotsPlaceholder />
          </div>
        )}
        <PostPageCommentsSection
          post={post}
          commentsRef={commentsRef}
          forceComments={forceComments}
        />
        {!isPageBottom3SlotEmpty && (
          <div className={styles.pageBottomSlot}>
            <PageBottom3SlotsPlaceholder />
          </div>
        )}
      </div>
    </div>
  );
};

type PostPageBiParams = {
  biActiveTabChanged: (payload: any) => void;
  biPostScrolled: (payload: any) => void;
  post?: NormalizedPost;
  readingSessionId: string;
  slug?: string;
  trackEvent: (eventName: string, params: any) => void;
  onSlugChange: () => void;
};

const useBiElement = ({
  biActiveTabChanged,
  biPostScrolled,
  onSlugChange,
  post,
  readingSessionId,
  slug,
  trackEvent,
}: PostPageBiParams) => {
  const lastSlugRef = useRef(slug);
  const slugOnChangeRef = useRef(onSlugChange);

  const blogPostViewTrackEvent = useCallback(() => {
    trackEvent('BlogPostView', {
      event: 'BlogPostView',
      eventCategory: 'Wix Blog',
      eventAction: 'BlogPostView',
      eventLabel: post?.title,
      origin: 'Wix Blog',
    });
  }, [post?.title, trackEvent]);

  useEffect(() => {
    blogPostViewTrackEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lastSlugRef.current !== slug && slug) {
      lastSlugRef.current = slug;
      blogPostViewTrackEvent();
      slugOnChangeRef.current();
    }
  }, [slug, blogPostViewTrackEvent]);

  const handleScroll: OnScrollHandler = useCallback(
    (event) => {
      biPostScrolled({
        post_stable_id: post?.id,
        is_demo: post?.isDemo,
        ...event,
      });
    },
    [post?.id, post?.isDemo, biPostScrolled],
  );

  const handleTabVisibilityChange: OnTabVisibilityChangeHandler = useCallback(
    (event) => {
      biActiveTabChanged({
        post_stable_id: post?.id,
        is_demo: post?.isDemo,
        ...event,
      });
    },
    [post?.id, post?.isDemo, biActiveTabChanged],
  );

  return (
    <ReadingTimeListener
      getContentContainer={getPostContentContainer}
      onScroll={handleScroll}
      onTabVisibilityChange={handleTabVisibilityChange}
      readingSessionId={readingSessionId}
    />
  );
};

const getPostContentContainer = () => {
  return document.querySelector('[data-hook="post"]')?.getBoundingClientRect();
};

const usePostPageSlice = (ownProps: Props) => {
  return useSelector((state) => {
    const postSlug =
      'postId' in ownProps.params ? ownProps.params.postId : resolvePostSlug(ownProps.params);

    let post = getPostByIdOrSlug(state, postSlug)!;

    post = isInPostPreview(getRoute(state)) ? { ...post, ...(post?.draft || {}) } : post;

    return {
      post,
      postSlug,
      isPostLoaded: getIsPostLoaded(state, postSlug),
      readingSessionId: getReadingSessionId(state),
    };
  });
};

export default PostPageRoot;
