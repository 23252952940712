import { useStyles } from '@wix/tpa-settings/react';
import {
  getTPASettingsIsCommentsEnabled,
  hasTPACommentsSettings,
  RelatedPostsLabel,
} from '@wix/communities-blog-client-common';
import stylesParams from '@app/components/Post/stylesParams';
import { useSelector } from '../components/runtime-context';
import { getIsCommentsEnabled } from '../selectors/app-settings-selectors';
import { getIsPostInPreview } from '../selectors/is-post-in-preview';
import { type UseFeedMetadataSettings } from './use-feed-metadata-settings';

export type UsePostPageSettings = UseFeedMetadataSettings & {
  showRelatedPostsSeeAllLink: boolean;
};

export const usePostPageSettings = (): UsePostPageSettings => {
  const styles = useStyles();
  const isPostInPreview = useSelector(getIsPostInPreview);
  const isTpaSettings = useSelector(hasTPACommentsSettings);
  const tpaSettingsEnabled = useSelector(getTPASettingsIsCommentsEnabled);
  const commentsEnabled = useSelector(getIsCommentsEnabled);

  const isCommentsEnabled =
    !isPostInPreview && (isTpaSettings ? tpaSettingsEnabled : commentsEnabled);

  return {
    showComments: isCommentsEnabled,
    showViewCount: styles.get(stylesParams.showViewCount),
    showCommentCount: styles.get(stylesParams.showCommentCount),
    showLikeCount: styles.get(stylesParams.showLikeCount),
    showAuthorName: styles.get(stylesParams.showAuthorName),
    showAuthorPicture: styles.get(stylesParams.showAuthorPicture),
    showPostUpdatedDate: styles.get(stylesParams.showPostUpdatedDate),
    showCategoryLabel: false, // Unused
    showPostDescription: true, // Unused
    showCategoryLabels: styles.get(stylesParams.showCategoryLabel),
    showPostTitle: styles.get(stylesParams.showPostTitle),
    showPublishDate: styles.get(stylesParams.showPostPublishDate),
    showReadingTime: styles.get(stylesParams.showReadingTime),
    showTags: styles.get(stylesParams.showTags),
    showMoreOptionsMenu: styles.get(stylesParams.showMoreOptionsMenu),
    isMetadataFooterVisible:
      styles.get(stylesParams.showViewCount) ||
      styles.get(stylesParams.showCommentCount) ||
      styles.get(stylesParams.showLikeCount),
    isMetadataHeaderVisible:
      styles.get(stylesParams.showAuthorName) ||
      styles.get(stylesParams.showPostPublishDate) ||
      styles.get(stylesParams.showReadingTime),
    showRelatedPosts: styles.get(stylesParams.showRelatedPosts),
    showRelatedPostsSeeAllLink: styles.get(stylesParams.showRelatedPostsSeeAllLink),
    relatedPostsLabelKey: styles.get(stylesParams.showRelatedPostsLabel)
      ? styles.get(stylesParams.showRelatedPostsLabelType) === RelatedPostsLabel.Recent
        ? 'recent-posts.title'
        : 'related-posts.title'
      : undefined,
    showPostRating: styles.get(stylesParams.showPostRating),
  } satisfies UsePostPageSettings;
};
